import { Component, OnInit } from '@angular/core';
//import { IncomingMessage } from 'http';
import { CForm } from '../c-form';

@Component({
  selector: 'app-imprimir',
  templateUrl: './imprimir.component.html',
  styleUrls: ['./imprimir.component.scss'],
})
export class ImprimirComponent extends CForm {
  novaMesa: string;
  numeroDeMesas: string;
  listaMesa: any[] = [];
  listaCheia: boolean = true;
  itemExcluir: any;

  ngOnInit(): void {
    super.ngOnInit();
    this.recuperarMesas();
  }

  async recuperarMesas() {
    this.loadScreen();

    try {
      const a = await this.db.recuperarMesas(this.getEmail());
      a.docs.forEach((i) => {
        this.listaMesa.push({
          numero: i.data().numero,
          id: i.data().id,
        });
      });
    } catch (error) {}

    if (this.listaMesa.length > 0) this.listaCheia = true;

    this.hideScreen(500);

    this.listaMesa.sort((a: any, b: any) => a.numero - b.numero);
  }

  async imprimir(item: any) {
    //window.open(`imprimirMesa/numero=${item.numero}`, '_blank');
    //window.open(`imprimir/${item.id}`, '_blank');

    var logo = await this.db
      .downloadFile(`cardapio/${this.empresa?.id}/logo`)
      .toPromise();

    window.open(
      `https://print.meufregues.com.br/imprimirMesa.php?id=${
        this.empresa?.id
      }&mesa=${item.numero}&logo=${encodeURIComponent(logo)}`,
      '_blank'
    );
  }

  async imprimirTodos() {
    var mesas = this.listaMesa.map((e) => e.numero).join('|');
    var logo = await this.db
      .downloadFile(`cardapio/${this.empresa?.id}/logo`)
      .toPromise();

    window.open(
      `https://print.meufregues.com.br/imprimirTodas.php?id=${
        this.empresa?.id
      }&mesas=${mesas}&logo=${encodeURIComponent(logo)}`,
      '_blank'
    );
  }

  adicionar() {
    if (
      this.novaMesa === undefined ||
      this.novaMesa === null ||
      this.novaMesa.trim() === ''
    ) {
      this.toast.onShow.emit({
        mensagem: 'Informe o número da mesa',
        tempo: 50 * 100,
        titulo: 'Erro',
        tipo: 'alert-error',
      });
      return;
    }

    const id = this.getId();

    this.listaMesa.push({
      numero: this.novaMesa,
      id: id,
    });
    this.listaMesa.sort((a: any, b: any) => a.numero - b.numero);
    this.db.adicionarMesa(
      this.getEmail(),
      id,
      Number.parseInt(this.novaMesa),
      {}
    );
    this.novaMesa = undefined;
  }

  gerar() {
    if (
      this.numeroDeMesas === undefined ||
      this.numeroDeMesas === null ||
      this.numeroDeMesas.trim() === '' ||
      parseInt(this.numeroDeMesas) < 1
    ) {
      this.toast.onShow.emit({
        mensagem: 'Informe o número de mesas',
        tempo: 50 * 100,
        titulo: 'Erro',
        tipo: 'alert-error',
      });
      return;
    }

    var inicial = 0;

    if (this.listaMesa.length > 0) {
      inicial = parseInt(this.listaMesa[this.listaMesa.length - 1].numero);
      //console.log('INICIAL', inicial);
    }

    for (
      var i = inicial + 1;
      i <= parseInt(this.numeroDeMesas) + inicial;
      i++
    ) {
      var id = this.getId();
      this.listaMesa.push({
        numero: i,
        id: id,
      });

      this.db.adicionarMesa(this.getEmail(), id, i, {});
    }

    this.listaMesa.sort((a: any, b: any) => a.numero - b.numero);

    this.numeroDeMesas = undefined;
  }

  remover(mesa) {
    this.itemExcluir = mesa.id;
  }

  removerMesa() {
    this.db.removerMesa(this.getEmail(), this.itemExcluir);
  }
}
