<form [formGroup]="formulario">
  <table class="table table-striped">
  <tr>
    <th>Dia da Semana</th>
    <th>Abre</th>
    <th>Fecha</th>
    <th style="width: 40px">Ação</th>
  </tr>
  <tr *ngFor="let i of lista; let idx = index" [class]="idx % 2? '' : 'striped'">
    <td>{{i.tipo}}</td>
    <td>{{i.abre | mask: 'Hh:m0'}} </td>
    <td>{{i.fecha | mask: 'Hh:m0'}} </td>
    <td style="padding: 2px;">
      <button type="button" class="btn btn-danger" (click)="deleteItem(i)">
        <i class="fa  fa-trash-o"></i>
      </button>
    </td>
  </tr>
</table>
<div class="row">
  <div class="col-lg-5 col-xs-12">
    <c-select rotulo="Selecione" formControlName="tipo" [options]="tipoHorario"></c-select>
  </div>
  <div class="col-lg-3 col-xs-6">
    <c-input rotulo="Abre às" mask="Hh:m0" formControlName="abre"></c-input>
  </div>
  <div class="col-lg-3 col-xs-6">
    <c-input rotulo="Fecha às" mask="Hh:m0" formControlName="fecha"></c-input>
  </div>
  <div class="col-lg-1 col-xs-12">
    <div class="form-group" style="margin-top: 2.2rem;">
      <button type="button" class="btn btn-primary" (click)="adicionar()">
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </div>
</div>
</form>
