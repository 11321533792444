import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImprimirComponent } from './imprimir.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CCardModule } from 'src/app/shared/c-card/c-card.module';
import { CInputModule } from 'src/app/shared/c-input/c-input.module';
import {NgxPrintModule} from 'ngx-print';
import { PrintComponent } from './print/print.component';
import { QRCodeModule } from 'angularx-qrcode';

const rota: Routes = [
  {
    path: '',
    component: ImprimirComponent,
  },
  {
    path: ':id',
    component: PrintComponent,
  },
];

@NgModule({
  declarations: [ImprimirComponent, PrintComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CCardModule,
    CInputModule,
    QRCodeModule,
    NgxPrintModule,
    RouterModule.forChild(rota),
  ], exports : [ImprimirComponent,PrintComponent]
})
export class ImprimirModule { }
