<div class="box">
  <div class="box-header with-border">
    <h3 class="box-title">{{ titulo }}</h3>

    <div class="box-tools pull-right">
      <ng-content select="[tools]"></ng-content>
    </div>

  </div>
  <div [class]="styleClass + ' box-body'">
    <ng-content *ngIf="!carregando"></ng-content>

    <div class="overlay" *ngIf="carregando">
      <i class="fa fa-refresh fa-spin"></i>
      <div class="label">carregando</div>
    </div>

  </div>
  <div class="box-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
