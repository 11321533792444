<label>{{ rotulo }}</label>

<div class="form-group" *ngIf="!icone">
  <input [separatorLimit]="separatorLimit" [mask]="mascara" [thousandSeparator]="thousandSeparator"
    [decimalMarker]="decimalMarker" [(ngModel)]="value" [type]="type" (blur)="blurEmitter($event)" mask="{{ mascara }}"
    [class]="isPendente ? 'form-control has-error' : 'form-control'" [id]="id" *ngIf="!textarea" type="text"
    [placeholder]="placeholder ? placeholder : ''" />
  <textarea [(ngModel)]="value" *ngIf="textarea" [class]="isPendente ? 'form-control has-error' : 'form-control'"
    [id]="id" [rows]="rows" (blur)="blurEmitter($event)" [placeholder]="placeholder ? placeholder : ''"></textarea>
</div>

<div class="input-group" *ngIf="icone">
  <input [decimalMarker]="decimalMarker" [separatorLimit]="separatorLimit" [mask]="mascara"
    [thousandSeparator]="thousandSeparator" [(ngModel)]="value" [type]="type" mask="{{ mascara }}"
    (blur)="blurEmitter($event)" [class]="isPendente ? 'form-control has-error' : 'form-control'" [id]="id"
    *ngIf="!textarea" type="text" [placeholder]="placeholder ? placeholder : ''" />
  <span class="input-group-btn" *ngIf="!textarea">
    <button type="button" class="btn btn-info btn-flat" (click)="onClick.emit(value)">
      <i [class]="icone"></i>
    </button>
  </span>
</div>