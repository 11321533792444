<div class="row">
  <div class="col-lg-2 col-xs-12"></div>

  <div class="col-lg-8 col-xs-12">


    <c-card titulo="Login">

      <div style="padding: 20px;">

        <div class="row">

          <div class="col-lg-2 col-xs-12">

          </div>

          <div class="col-lg-8 col-xs-12">

            <a class="btn btn-block btn-social btn-google" (click)="login()">
              <i class="fa fa-google-plus"></i> Acessar com sua conta Google
            </a>

          </div>

          <div class="col-lg-2 col-xs-12">

          </div>


        </div>




      </div>

    </c-card>


  </div>

  <div class="col-lg-2 col-xs-12"></div>
</div>
