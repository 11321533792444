<form [formGroup]="formulario">
  <div class="row">
    <div class="col-lg-12 col-xs-12">
      <c-card titulo="Geral">
        <div>
          <div class="row">


            <div class="col-lg-4 col-xs-12">

              <c-imagem [imagem]="logo" [width]="300" [height]="300" (updateImage)="atualizarImagem($event)"></c-imagem>


            </div>


            <div class="col-lg-8 col-xs-12">
              <c-input
                rotulo="Nome:"
                placeholder="Nome pessoa física ou jurídica."
                formControlName="nome"
              ></c-input>
            </div>
            <div class="col-lg-4 col-xs-12"></div>
            <div class="col-lg-8 col-xs-12">
              <c-input
                rotulo="Descrição:"
                textarea="true"
                formControlName="descricao"
                placeholder="Breve descrição sobre os produtos ou serviços."
              ></c-input>
            </div>
          </div>
        </div>
      </c-card>

      <c-card titulo="Qual é sua localização?">
        <div>
          <div class="row">
            <div class="col-lg-6 col-xs-12">
              <c-input
                (onClick)="buscaCep()"
                rotulo="CEP:"
                placeholder="Buscar CEP"
                formControlName="cep"
                mask="00.000-000"
                icone="fa fa-fw fa-search"
              ></c-input>
            </div>

            <div class="col-lg-6 col-xs-12">
              <c-input
                rotulo="Logradouro:"
                placeholder="Logradouro"
                formControlName="logradouro"
              ></c-input>
            </div>

            <div class="col-lg-6 col-xs-12">
              <c-input
                rotulo="Bairro:"
                placeholder="Bairro"
                formControlName="bairro"
              ></c-input>
            </div>

            <div class="col-lg-6 col-xs-12">
              <c-input
                rotulo="Municipio:"
                placeholder="municipio"
                formControlName="municipio"
              ></c-input>
            </div>

            <div class="col-lg-6 col-xs-12">
              <c-input
                rotulo="Complemento:"
                placeholder="complemento"
                formControlName="complemento"
              ></c-input>
            </div>

            <div class="col-lg-6 col-xs-12">
              <c-input
                rotulo="Número:"
                placeholder="numero"
                formControlName="numero"
              ></c-input>
            </div>

            <div class="col-lg-6 col-xs-12">
              <c-select formControlName="uf" rotulo="UF:" [options]="ufs">
              </c-select>
            </div>
          </div>
        </div>
      </c-card>

     <!-- horario -->

     <c-card titulo="Horários de atendimento">
      <div>
        <div class="row">
          <div class="col-lg-12 col-xs-12">
            <c-horario [horarios]="horarios" (update)="atualizarHorario($event)"></c-horario>
          </div>
        </div>
      </div>
    </c-card>
    <!-- horario -->



    <!-- TELEFONE -->
    <c-card titulo="Telefones">
      <div>
        <div class="row">
          <div class="col-lg-12 col-xs-12">
            <c-telefone  [telefones]="telefones" (update)="atualizarTelefone($event)"></c-telefone>
          </div>
        </div>
      </div>
    </c-card>

    <!-- TELEFONE -->




    </div>

    <div class="col-lg-12 col-xs-12">
      <div class="box box-widget">

        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="salvar()">Salvar</button>
          </div>

      </div>
    </div>
  </div>
</form>
