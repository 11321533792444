import { Component, OnInit } from '@angular/core';
import { CForm } from '../c-form';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-mensagem',
  templateUrl: './mensagem.component.html',
  styleUrls: ['./mensagem.component.scss']
})
export class MensagemComponent extends CForm implements OnInit {
  mensagens= [];
  mensagemSelecionada:any;
  idSelecionada:any;
  cardapio:any;
  async ngOnInit(){
    super.ngOnInit();
    this.loadScreen('Carregando');

    setTimeout(
      async () =>{
        if(this.empresa?.id) {
          this.mensagens = await this.db.recuperarMensagens(this.getEmail());
        }
        this.hideScreen();
      },500
    );

  }

  async aceitar(value:boolean){
    if(value === true){
      await  this.db.adicionarCardapio(this.cardapio.id,this.cardapio.nome,this.getEmail(),this.cardapio);
      this.mensagemSelecionada.transferido = value;
      await this.db.atualizarMensagem(this.idSelecionada,this.mensagemSelecionada);
    }

  }

  async lerMensagem(m:any){
    this.mensagemSelecionada = m.mensagem;
    this.idSelecionada = m.id;
    this.mensagemSelecionada.lido = true;
    this.mensagens[m] =this.mensagemSelecionada;
    const _tmp:any = await this.db.recuperarTransferencia(m.mensagem.mensagem.id);
    this.cardapio = _tmp.cardapio;
    await this.db.atualizarMensagem(this.idSelecionada,this.mensagemSelecionada);
  }

}
//63938cf0-eb0c-4391-8470-94877da51cab
