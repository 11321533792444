import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CLogoComponent } from './c-logo.component';

@NgModule({
  declarations: [CLogoComponent],
  imports: [CommonModule],
  exports: [CLogoComponent],
})
export class CLogoModule {}
