<header class="main-header">
  <nav class="navbar navbar-static-top">
    <div class="container">
      <c-logo></c-logo>



      <div class="collapse navbar-collapse pull-left" id="navbar-collapse" *ngIf="isAuth && usuario">
        <ul class="nav navbar-nav" *ngFor="let i of menu">
          <li [class]="router.url === i.url? 'active' : '' "><a [href]="i.url">{{i.label}}</a></li>
        </ul>
      </div>

      <div class="navbar-custom-menu" >
        <ul class="nav navbar-nav" *ngIf="!isAuth">
          <li class="dropdown user user-menu" >
            <a href="#" class="dropdown-toggle" (click)="login()" data-toggle="dropdown">
              <img
              src="assets/img/avatar.png"
              class="user-image"
              alt="User Image"
            />
              <span class="hidden-xs">Acessar</span>
            </a>
          </li>
        </ul>
        <ul class="nav navbar-nav" *ngIf="isAuth && usuario">
          <li class="dropdown  {{i?.classe}}" *ngFor="let i of itens" (click)="i.emitter.emit($event)">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
              <i [class]="i.icone"></i>
              <img *ngIf="i?.img"
              [src]="i?.img"
              class="user-image"
              alt="User Image"
            />
              <span class="label label-success" *ngIf="i?.contador">{{i?.contador}}</span>
              <span class="hidden-xs" *ngIf="i?.label">{{i?.label}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
