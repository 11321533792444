<c-card titulo="Mensagens">
  <div class="table-responsive mailbox-messages">
    <table class="table table-hover table-striped">
      <tbody>
        <tr *ngFor="let m of mensagens; let i = index">
          <td class="mailbox-star" *ngIf="m?.mensagem?.lido"></td>
          <td class="mailbox-star" *ngIf="!m?.mensagem?.lido">
            <a href="#"><i class="fa fa-star text-yellow"></i></a>
          </td>

          <td class="mailbox-name">
            {{ m?.mensagem.origem }}
          </td>

          <td class="mailbox-subject" *ngIf="m?.mensagem?.tipo === '[T]'">
            <b>Transferência</b>&nbsp;

            <i *ngIf="m?.mensagem?.transferido === true? true:false || false" class="fa  fa-check-square"></i>

          </td>
          <td class="mailbox-date">{{ m?.mensagem?.dataHora }}</td>
          <td class="mailbox-attachment">
            <button
            (click)="lerMensagem(m)"
            type="button" class="btn btn-block btn-success btn-sm" data-toggle="modal" data-target="#modal-default">Abrir</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</c-card>

<div class="modal fade" id="modal-default">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Trasferência</h4>
      </div>
      <div class="modal-body">
        <div *ngIf="mensagemSelecionada?.transferido === true? true:false || true">
          <h3>Transferido.</h3>
        </div>
        <br>

        <div *ngIf="mensagemSelecionada?.transferido === true? false:true || true">
          <h4>Olá, você recebeu uma transferência de cardápio.</h4>
          <p>Aceita receber esse cardáio?</p>

        </div>



        <dl>
          <dt>{{cardapio?.nome}}</dt>
          <dd>Categoria(s) : {{cardapio?.dados?.length || 0}}</dd>
        </dl>

      <hr>

      <dl *ngFor="let c of cardapio?.dados">
        <dt>{{c?.categoria}}</dt>
        <dd>Produto(s) : {{c?.produtos?.length || 0}}</dd>
      </dl>
      </div>
      <div class="modal-footer" *ngIf="mensagemSelecionada?.transferido === true? true:false || false">
        <button type="button" class="btn btn-success"  data-dismiss="modal">Fechar</button>
      </div>

      <div class="modal-footer" *ngIf="mensagemSelecionada?.transferido === true? false:true || true">
        <button type="button" class="btn btn-danger" (click)="aceitar(false)" data-dismiss="modal">Não Aceitar</button>
        <button type="button" class="btn btn-primary" (click)="aceitar(true)" data-dismiss="modal">Aceitar</button>
      </div>

    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
