import { AdicionarComponent } from './adicionar/adicionar.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CardapioComponent } from './cardapio.component';
import { CCardModule } from 'src/app/shared/c-card/c-card.module';
import { AdicionarModule } from './adicionar/adicionar.module';
import { CInputModule } from 'src/app/shared/c-input/c-input.module';

const rota: Routes = [
  {
    path: '' , component: CardapioComponent,
  },
  {
    path: 'cardapio/adicionar' , component: AdicionarComponent,
  },
  {
    path: 'cardapio/editar/:id' , component: AdicionarComponent,
  }
];


@NgModule({
  declarations: [CardapioComponent],
  imports: [
    CommonModule,
    FormsModule,
    CCardModule,
    CInputModule,
    AdicionarModule,
    RouterModule.forChild(rota),
  ],exports: [CardapioComponent]
})
export class CardapioModule { }
