import { UsuarioLogado } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { CForm } from '../c-form';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends CForm {
  carregando = true;

  ngOnInit(): void {
    super.ngOnInit();
    this.init();
  }

  async abrir() {
    this.empresa.aberto = !this.empresa.aberto;
    ////console.log(this.getEmail(), this.empresa);
    var r = await this.db.atualizarEmpresa2(this.getEmail(), this.empresa);
  }

  async init() {
    setTimeout(async () => {
      /**
       *  this.db.listarTodosOsArquivos(`cardapio/${this.empresa.id}/`).subscribe(
          (d:any) => {
            d.items.forEach(element => {
              element.listAll().subscribe(
                (x)=>{
                  //console.log('xxxx ',x);
                }
              );

              element.getMetadata().then(
                (e)=>{
                  //console.log('DADOS RECEBSO ', e.name);
                }
              );
            });
          }
        );
       */

      if (this.empresa === null || !this.empresa?.aberto) {
        this.empresa.aberto = false;
      }

      this.carregando = false;
    }, 500);
  }
}
