import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentData,
  QuerySnapshot,
} from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { v4 as uuidv4 } from 'uuid';
@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor(
    public db: AngularFirestore,
    public http: HttpClient,
    public storage: AngularFireStorage
  ) {}

  /** EMPRESA */

  async atualizarEmpresa(usuario: string, dados: any): Promise<any> {
    const empresa = await this.recuperarDadosEmpresa(usuario);
    if (empresa !== undefined && empresa !== null) {
      empresa.dados = dados;
      return this.db.collection('cardapio').doc(empresa.id).update({
        dados: dados,
      });
    } else {
      return this.db.collection('cardapio').doc(empresa.id).set({
        empresa: dados,
      });
    }
  }

  async atualizarEmpresa2(usuario: string, empresa: any): Promise<any> {
    //return this.db.collection('cardapio').doc(empresa.id).set(empresa);
    return this.db.collection('cardapio').doc(empresa.id).update(empresa);
  }

  async recuperarDadosEmpresa(usuario: string): Promise<any> {
    const empresa: any = await this.db
      .collection('cardapio')
      .ref.where('usuario', '==', usuario)
      .get();

    if (!empresa?.docs[0]?.data()) {
      return await this.iniciarEmpresa(usuario);
    }
    return empresa?.docs[0]?.data();
  }

  async recuperarDadosEmpresaId(id: string): Promise<any> {
    const empresa: any = await this.db
      .collection('cardapio')
      .ref.where('id', '==', id)
      .get();
    return empresa?.docs[0]?.data();
  }

  async iniciarEmpresa(usuario: string, dados?: any): Promise<any> {
    if (dados === undefined || dados === null) {
      dados = new Object();
    }
    dados.pago = true;
    dados.aberto = false;
    dados.data = new Date().toLocaleString();
    dados.id = uuidv4();
    dados.usuario = usuario;
    await this.db.collection('cardapio').doc(dados.id).set(dados);
    return dados;
  }

  async ativarCardapio(usuario: string, id: string) {
    var empresa = await this.recuperarDadosEmpresa(usuario);
    empresa.ativo = id;

    return this.db.collection('cardapio').doc(empresa.id).update(empresa);
  }

  /** FIM EMPRESA */

  /** CARDAPIO */

  async recuperarCardapioAtivoVisualizacao(id: string) {
    //console.log('ID', id);
    const empresa: any = await this.recuperarDadosEmpresaId(id);
    //console.log('EMPRESA', empresa);
    const cardapios: any = await this.recuperarCardapios(empresa.usuario);
    //console.log('CARDAPIOS', cardapios);
    var ativo;
    cardapios.docs.forEach((e: any) => {
      const _ativo = e.data();
      if (_ativo.id === empresa.ativo) {
        ativo = _ativo;
      }
    });
    //console.log('ATIVO', ativo);
    return ativo;
  }

  async recuperarCardapioAtivo(usuario: string) {
    const empresa: any = await this.recuperarDadosEmpresa(usuario);
    const cardapios: any = await this.recuperarCardapios(usuario);
    var ativo;
    cardapios.docs.forEach((e: any) => {
      const _ativo = e.data();
      if (_ativo.id === empresa.ativo) {
        ativo = _ativo;
      }
    });

    return ativo;
  }

  async recuperarCardapios(usuario: string) {
    //console.log('USUARIO', usuario);
    const empresa: any = await this.recuperarDadosEmpresa(usuario);
    const resp = await this.db
      .collection('cardapio')
      .doc(empresa.id)
      .collection('cardapios')
      .get()
      .toPromise();

    return resp;
  }

  async adicionarCardapio(
    id: string,
    nome: string,
    usuario: string,
    dados: any
  ) {
    const empresa: any = await this.recuperarDadosEmpresa(usuario);
    const idCardapio = id;
    this.db
      .collection('cardapio')
      .doc(empresa.id)
      .collection('cardapios')
      .doc(idCardapio)
      .set({
        id: id,
        nome: nome,
        dados: dados,
      })
      .then((r) => {
        //console.log('erro no', r);
      })
      .catch((r) => {
        //console.log('erro no', r);
      });
  }

  /** FIM CARDAPIO */

  /** FIM MESAS */

  async recuperarMesas(usuario: string) {
    const empresa: any = await this.recuperarDadosEmpresa(usuario);
    const resp = this.db
      .collection('cardapio')
      .doc(empresa.id)
      .collection('mesas')
      .get()
      .toPromise();

    return resp;
  }

  async recuperarMesaPorId(usuario: string, id: string): Promise<any> {
    const empresa: any = await this.recuperarDadosEmpresa(usuario);
    const mesa: any = await this.db
      .collection('cardapio')
      .doc(empresa.id)
      .collection('mesas')
      .ref.where('id', '==', id)
      .get();

    if (!mesa?.docs[0]?.data()) {
      return null;
    }
    return mesa?.docs[0]?.data();
  }

  async adicionarMesa(usuario: string, id: string, numero: number, dados: any) {
    const empresa: any = await this.recuperarDadosEmpresa(usuario);
    const idMesa = id;
    this.db
      .collection('cardapio')
      .doc(empresa.id)
      .collection('mesas')
      .doc(idMesa)
      .set({
        id: id,
        numero: numero,
        dados: dados,
      })
      .then((r) => {
        //console.log('erro no', r);
      })
      .catch((r) => {
        //console.log('erro no', r);
      });
  }

  async removerMesa(usuario: string, id: string) {
    const empresa: any = await this.recuperarDadosEmpresa(usuario);
    const idMesa = id;
    //console.log(idMesa);
    this.db
      .collection('cardapio')
      .doc(empresa.id)
      .collection('mesas')
      .doc(idMesa)
      .delete()
      .then((r) => {
        location.reload();
      })
      .catch((r) => {
        //console.log('erro', r);
      });
  }

  /** FIM MESAS */

  /** MENSAGEM */

  async atualizarMensagem(id: string, mensagem: any) {
    return await this.db.collection('mensagens').doc(id).set(mensagem);
  }

  async recuperarMensagens(usuario: string) {
    let mensagens = [];

    const msg = await this.db
      .collection('mensagens')
      .ref.where('destino', '==', usuario)
      .get();
    msg.docs.forEach((m: any) => {
      mensagens.push({
        id: m.id,
        mensagem: m.data(),
      });
      //console.log(m.data());
    });
    return mensagens;
  }

  async mensagem(
    origem: string,
    destino: string,
    mensagem: any,
    tipo: string = '[MENSAGEM]'
  ) {
    const id = uuidv4();
    this.db.collection('mensagens').doc(id).set({
      dataHora: new Date().toLocaleString(),
      origem: origem,
      destino: destino,
      lido: false,
      tipo: tipo,
      mensagem: mensagem,
    });
  }

  /** FIM MENSAGEM */

  /** TRANSFERENCIAS */
  async recuperarTransferencia(id: string) {
    const dados = await this.db
      .collection('transferencias')
      .doc(id)
      .get()
      .toPromise();
    //console.log(dados);
    return dados?.data();
  }

  async transferirPara(origem: string, destino: string, cardapio: any) {
    const id = uuidv4();
    const empresa: any = await this.recuperarDadosEmpresa(origem);
    this.db.collection('transferencias').doc(id).set({
      dataHora: new Date().toLocaleString(),
      origem: origem,
      destino: destino,
      empresa: empresa.id,
      cardapio: cardapio,
    });

    await this.mensagem(
      origem,
      destino,
      {
        id: id,
      },
      '[T]'
    );
    return id;
  }

  /** FIM TRANSFERENCIAS */

  /**
   * ARQUIVOS
   */

  uploadFile(caminho: string, file: File) {
    return this.storage.upload(`${caminho}`, file);
  }
  listarTodosOsArquivos(caminho: string) {
    const ref = this.storage.ref(caminho);
    return ref.listAll();
  }

  downloadFile(caminho: string) {
    const ref = this.storage.ref(caminho);
    return ref.getDownloadURL();
  }
}
