import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransferenciaComponent } from './transferencia.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CCardModule } from 'src/app/shared/c-card/c-card.module';
import { CInputModule } from 'src/app/shared/c-input/c-input.module';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxPrintModule } from 'ngx-print';


const rota: Routes = [
  {
    path: '',
    component: TransferenciaComponent,
  },
];


@NgModule({
  declarations: [TransferenciaComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CCardModule,
    CInputModule,
    QRCodeModule,
    NgxPrintModule,
    RouterModule.forChild(rota),
  ],
  exports : [TransferenciaComponent]
})
export class TransferenciaModule { }
