<div class="row">

  <div class="col-lg-4 col-xs-12" >
    <c-card titulo="Seu QrCode" [carregando]="carregando" >
      <div style="text-align: center;">
        <qrcode *ngIf="empresa?.id" [qrdata]="'https://meufregues.com.br/v/'+empresa.id" width="250"></qrcode>
      </div>
      <p><b>QrCode</b> do estabelecimento, não associado a nenhuma mesa</p>
    </c-card>
  </div>
  <div class="col-lg-4 col-xs-12" >
    <c-card titulo="Aberto/Fechado" [carregando]="carregando" >

      <div class="info-box">

        <span class="info-box-icon bg-blue" *ngIf="this.empresa?.aberto===true"><i class="fa fa-list"></i></span>
        <span class="info-box-icon bg-red" *ngIf="this.empresa?.aberto===false"><i class="fa fa-hand-stop-o"></i></span>



        <div class="info-box-content">
          <span class="info-box-text" *ngIf="this.empresa?.aberto===true">Aberto</span>
          <span class="info-box-text" *ngIf="this.empresa?.aberto===false">Fechado</span>


          <button type="button" class="btn btn-block btn-danger btn-flat" (click)="abrir()" *ngIf="this.empresa?.aberto===true">Fechar</button>
          <button type="button" class="btn btn-block btn-success btn-flat" (click)="abrir()" *ngIf="this.empresa?.aberto===false">Abrir</button>


        </div>
        <!-- /.info-box-content -->
      </div>

    </c-card>
  </div>
</div>


