import { Component } from '@angular/core';
import { CForm } from '../c-form';

@Component({
  selector: 'app-v',
  templateUrl: './v.component.html',
  styleUrls: ['./v.component.scss'],
})
export class VComponent extends CForm {
  id: string;
  mesa: string;
  cardapio: any;
  logo: string;
  empresa: any = [];
  listaImg: any = [];

  ngOnInit(): void {
    this.loadScreen();
    super.ngOnInit();

    setTimeout(() => {
      this.id = this.parametros.id;
      this.mesa = this.parametros.mesa;
      this.init();
    }, 1000);
  }

  async init() {
    try {
      this.logo = await this.download();
    } catch (error) {
      //console.log('ERRO LOGO', error);
    }

    this.hideScreen(500);
    this.empresa = await this.db.recuperarDadosEmpresaId(this.id);
    this.cardapio = await this.db.recuperarCardapioAtivoVisualizacao(this.id);

    this.cardapio.dados?.forEach(async (c) => {
      var lista = c.produtos;
      lista.forEach(async (e) => {
        if (e.ext) {
          var _img = await this.db
            .downloadFile(
              `cardapio/${this.empresa.id}/produtos/${e.id}.${e.ext}`
            )
            .toPromise();
          e._img = _img;
          this.listaImg.push({
            id: e.id,
            ext: e.ext,
            imagem: _img,
          });
        }
      });
    });
  }

  getImagem(id: String) {
    const a = this.listaImg.filter((e) => e.id === id);

    if (a.length === 0) {
      return;
    }
    return a[0].imagem;
  }

  download() {
    if (this.id !== undefined || this.id !== undefined) {
      return this.db.downloadFile(`cardapio/${this.id}/logo`).toPromise();
    }
  }
}
