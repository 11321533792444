// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  nome:'Meu Fregues',

  firease: {
    apiKey: 'AIzaSyCs4Q7V0BQG0FT_B7TURfbBNhAEK1YRQPA',
    authDomain: 'cardapiodigital-fbc8b.firebaseapp.com',
    projectId: 'cardapiodigital-fbc8b',
    storageBucket: 'cardapiodigital-fbc8b.appspot.com',
    messagingSenderId: '263340819045',
    appId: '1:263340819045:web:9c50c081374034b8917c8c',
    measurementId: 'G-4L4T8EWDTN',
  },
  firease_c: {
    apiKey: 'AIzaSyAsNG69arvHrean21G7o-C9xt8DT2mOdKQ',
    authDomain: 'cardapioapp-cf426.firebaseapp.com',
    databaseURL: 'https://cardapioapp-cf426.firebaseio.com',
    projectId: 'cardapioapp-cf426',
    storageBucket: 'cardapioapp-cf426.appspot.com',
    messagingSenderId: '879817274447',
    appId: '1:879817274447:web:7a12f13052e24a6072663a',
    measurementId: 'G-B5VZ3H5CX7',
  },
  menu : [
    {label:'Home', url : '/home' },
    {label:'Empresa', url : '/empresa' },
    {label:'Cardápio', url : '/cardapio' },
    {label:'Imprimir', url : '/imprimir' },
    {label:'Mensagem', url : '/mensagem' },
    {label:'Sair', url : '/login/sair' },
  ]
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
