import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'c-alerta',
  templateUrl: './c-alerta.component.html',
  styleUrls: ['./c-alerta.component.scss']
})
export class CAlertaComponent {
  displayModal = false;
  mensagem:string;
  titulo:string ='Alerta';
  tipo:string = 'alert alert-info alert-dismissible';

  constructor(
    public service : ToastService

  ) {
    this.service.onShow.subscribe((data: any) => {
      if( data !== null && data !== undefined ){
        this.mensagem = data.mensagem;

        if(data.tipo){
          this.tipo = `alert ${data.tipo} alert-dismissible`;
        }
        if(data.titulo){
          this.titulo = data.titulo;
        }
      }
      this.displayModal = true;
      this._hide(data.tempo);
    });

    this.service.onHide.subscribe(() => {
        this._hide(200);
    });


  }

  private _hide(tempo){
    setTimeout( () => {
      this.displayModal = false;
    }, tempo);
  }

}
