import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: './modules/login/login.module#LoginModule'

  },
  {
    path: 'home',
    loadChildren: './modules/home/home.module#HomeModule',
    canActivate:[AuthGuardService]
  },
  {
    path: 'empresa',
    loadChildren: './modules/empresa/empresa.module#EmpresaModule',
    canActivate:[AuthGuardService]
  },
  {
    path: 'cardapio',
    loadChildren: './modules/cardapio/cardapio.module#CardapioModule',
    canActivate:[AuthGuardService]
  },
  {
    path: 'imprimir',
    loadChildren: './modules/imprimir/imprimir.module#ImprimirModule',
    canActivate:[AuthGuardService]
  },
  {
    path: 'mensagem',
    loadChildren: './modules/mensagem/mensagem.module#MensagemModule',
    canActivate:[AuthGuardService]
  },
  {
    path: 'v/:id/:mesa',
    loadChildren: './modules/v/v.module#VModule',
  }
  ,
  {
    path: 'v/:id',
    loadChildren: './modules/v/v.module#VModule',
  },
  {
    path: 'transferencia/:id',
    loadChildren: './modules/transferencia/transferencia.module#TransferenciaModule',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
