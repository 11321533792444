<c-card [titulo]="'Mesas'">
  <div tools>
    <button type="button" class="btn btn-success" data-toggle="modal" data-target="#modal-mesas"><i
        class="fa fa-plus"></i>&nbsp; Criar Mesas</button>
    &nbsp;
    <button type="button" class="btn btn-success" data-toggle="modal" data-target="#modal-default"><i
        class="fa fa-plus"></i>&nbsp; Nova Mesa</button>
    &nbsp;
    <button type="button" class="btn btn-info" (click)="imprimirTodos()"><i class="fa fa-print"></i>&nbsp;
      Imprimir Todas</button>


    <div class="modal fade" id="modal-default">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Mesas</h4>
          </div>
          <div class="modal-body">

            <c-input rotulo="Número da Nova Mesa" mask="00" placeholder="ex.: 01" [(ngModel)]="novaMesa"></c-input>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Cancelar
            </button>
            <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="adicionar()">
              Salvar

            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <div class="modal fade" id="modal-mesas">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Mesas</h4>
          </div>
          <div class="modal-body">

            <c-input rotulo="Número de mesas a serem criadas" mask="00" placeholder="ex.: 01"
              [(ngModel)]="numeroDeMesas"></c-input>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Cancelar
            </button>
            <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="gerar()">
              Salvar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

  </div>

  <div class="box-body">
    <div class="row">
      <div class="col-lg-2 col-xs-6" *ngFor="let m of listaMesa">
        <!-- small box -->
        <div class="small-box bg-aqua">
          <div class="inner">
            <h3>{{m.numero}}</h3>
            <p><br /></p>
          </div>
          <div class="icon">
            <i class="ion ion-qr-scanner" style="color: #fff;"></i>
          </div>
          <div style="background-color: #fff; padding-top: 4px; padding-bottom: 4px;">
            <button type="button" class="btn btn-success" (click)="imprimir(m)">
              <i class="fa  fa-print"></i>
            </button>
            &nbsp;
            &nbsp;
            <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#modal-excluir"
              (click)="remover(m)">
              <i class="fa  fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-excluir">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Excluir</h4>
        </div>
        <div class="modal-body">
          <p>Deseja remover esta mesa?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">
            Não
          </button>
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="removerMesa()">
            Sim
          </button>
        </div>
      </div>
    </div>
  </div>


</c-card>