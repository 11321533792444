import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CForm } from 'src/app/modules/c-form';

@Component({
  selector: 'c-telefone',
  templateUrl: './c-telefone.component.html',
  styleUrls: ['./c-telefone.component.scss'],
})
export class CTelefoneComponent extends CForm implements OnInit {
  @Output() update = new EventEmitter<any>();
  tipoTelefone = [];
  @Input('telefones') lista = [];

  ngOnInit(): void {
    this.init();
    this._carregarForm();
    //console.log(this.tipoTelefone);
  }

  private _carregarForm(): void {
    this.add('tipo').add('numero');
  }

  async init() {
    await this._carresgarTipoTelefone();
  }
  private _carresgarTipoTelefone(): void {
    this.httpService.tipoTel().subscribe((data: any) => {
      this.tipoTelefone = data;
    });
  }
  deleteItem(i: any) {
    this.lista = this.lista.filter((o: any) => o !== i);
    this.update.emit(this.lista);
  }
  adicionar() {
    if (this.lista === undefined || this.lista === null) {
      this.lista = [];
    }
    this.lista.push(this.formulario.value);
    this.formulario.reset();
    this.update.emit(this.lista);
  }
}
