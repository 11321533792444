import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from './../../../environments/environment';
import { AuthService, UsuarioLogado } from './../../services/auth.service';

@Component({
  selector: 'c-topo',
  templateUrl: './c-topo.component.html',
  styleUrls: ['./c-topo.component.scss'],
})
export class CTopoComponent implements OnInit {
  contador = 0;
  itens: ItemMenu[];
  eeUsuario = new EventEmitter();
  eeMensagem = new EventEmitter();
  isAuth = false;
  usuario: UsuarioLogado;
  menu: any;

  constructor(
    public auth: AuthService,
    private router: Router,
    public loading: LoadingService,
    public db: FirebaseService
  ) {
    this.menu = environment.menu;
  }

  ngOnInit(): void {
    if (this.usuario == undefined) {
      setTimeout(() => {
        this.usuario = this.auth.getUser();
        if (this.usuario !== null && this.usuario !== undefined) {
          this.isAuth = true;
          this.criarMenu();
        }
      }, 500);
    }

    var user = this.auth.getUser();
    if (user !== null) {
      this.db.recuperarMensagens(user.email.toString()).then((m: any) => {
        if (!m?.lido) {
          m.forEach((e: any) => {
            if (e?.mensagem?.lido === false) {
              this.contador++;
            }
          });
        }
      });
    }
  }

  async login() {
    this.loading.onShow.emit('Aguarde');
    this.isAuth = await this.auth.doGoogleLogin();
    this.usuario = this.auth.getUser();

    if (this.usuario !== null && this.usuario !== undefined) {
      this.criarMenu();
      setTimeout(() => {
        this.loading.onHide.emit();
        this.router.navigate(['/home']);
      });
    } else {
      this.loading.onHide.emit();
    }
  }

  criarMenu() {
    this.itens = [];

    this.eeMensagem.subscribe((e) => {
      this.router.navigate(['/mensagem']);
    });
    this.itens.push(
      new ItemMenu(
        '',
        'fa fa-envelope-o',
        this.eeMensagem,
        this.contador.toString(),
        'messages-menu'
      )
    );
    this.itens.push(
      new ItemMenu(
        this.usuario?.displayName?.toString(),
        null,
        this.eeUsuario,
        null,
        'user user-menu',
        this.usuario?.photoURL?.toString()
          ? this.usuario.photoURL.toString()
          : 'assets/lte/img/user2-160x160.jpg'
      )
    );
  }
}

export class ItemMenu {
  label: string;
  icone: string;
  contador: string;
  emitter: EventEmitter<any>;
  classe: string;
  img: string;

  constructor(
    label: string,
    icone: string,
    emitter: EventEmitter<any>,
    contador: string,
    classe?: string,
    img?: string
  ) {
    this.label = label;
    this.icone = icone;
    this.contador = contador;
    this.emitter = emitter;
    this.classe = classe;
    this.img = img;
  }
}
