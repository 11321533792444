import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {


  public onShow: EventEmitter<any>;
  public onHide: EventEmitter<void>;

  constructor() {
    this.onShow = new EventEmitter<any>();
    this.onHide = new EventEmitter<void>();
  }
}
