import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { LoadingService } from 'src/app/services/loading.service';
import { CLogoModule } from '../c-logo/c-logo.module';
import { AuthService } from './../../services/auth.service';
import { CTopoComponent } from './c-topo.component';



@NgModule({
  declarations: [CTopoComponent],
  imports: [
    CommonModule,
    CLogoModule
  ],
  providers : [LoadingService,FirebaseService],
  exports : [CTopoComponent]

})
export class CTopoModule { }
