import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'c-imagem',
  templateUrl: './c-imagem.component.html',
  styleUrls: ['./c-imagem.component.scss']
})
export class CImagemComponent implements OnInit {
  private static KEY ='@CARDAPIO#EMPRESA#TIP';
  @Input() mostarInfo = false;
  @Input() width = 300;
  @Input() height = 300;
  @Output() updateImage = new EventEmitter<any>();
  @Input() esconderBotao = false;
  @Input() abrirClick = true;
  @ViewChild('imageInput', {read: ElementRef}) imageInput : ElementRef;

  @Input('imagem') url = '';
  constructor() {
    const a = localStorage.getItem(CImagemComponent.KEY);
    if(a === undefined || a === null){
      this.mostarInfo = true;
    }
  }

  clickImagem(): void {
    if(this.abrirClick){
      this.imageInput.nativeElement.click();
    }
  }

  ngOnInit(): void {
  }

   onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.url = event.target.result.toString();
        this.updateImage.emit(this.url);
      }
      localStorage.setItem(CImagemComponent.KEY, 'true');
    }
  }

}
