import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CAlertaComponent } from './c-alerta.component';



@NgModule({
  declarations: [CAlertaComponent],
  imports: [
    CommonModule
  ],
  exports : [CAlertaComponent]
})
export class CAlertaModule { }
