import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  static USR = '@CARDAPIO#USUARIO';
  usuario: UsuarioLogado;
  constructor(public auth: AngularFireAuth) {
    this.auth.onAuthStateChanged((user: firebase.default.User) => {
      this._updateUser(user);
    });
  }

  getUser(): UsuarioLogado {
    this.usuario = null;
    let _usr = localStorage.getItem(AuthService.USR);
    if (_usr !== null && _usr !== undefined) {
      this.usuario = JSON.parse(_usr);
    }
    return this.usuario;
  }

  _updateUser(user: any) {
    if (user) {
      const { displayName, email, photoURL, refreshToken } = user;
      this.usuario = {
        displayName,
        email,
        photoURL,
        refreshToken,
      } as UsuarioLogado;
    } else {
      this.usuario = null;
    }
    localStorage.setItem(AuthService.USR, JSON.stringify(this.usuario));
  }

  async sair() {
    localStorage.clear();
    await this.auth.signOut();
  }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.default.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.auth
        .signInWithPopup(provider)
        .then((dados: any) => {
          if (dados.user.email) {
            this._updateUser(dados.user);
            return resolve(true);
          } else {
            this._updateUser(null);
            return resolve(false);
          }
        })
        .catch((err: any) => {
          //console.log('Login err', err);
          this._updateUser(null);
          return resolve(false);
        });
    });
  }
}

export interface UsuarioLogado {
  displayName: String;
  email: String;
  photoURL: String;
  refreshToken: String;
}
