<c-topo *ngIf="!router.url.startsWith('/v/')"></c-topo>

<div class="content-wrapper">
  <div class="container">
    <section class="content">
      <c-alerta></c-alerta>
      <router-outlet></router-outlet>
    </section>
  </div>
</div>
<c-loading></c-loading>


