import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { CForm } from './../../modules/c-form';

@Component({
  selector: 'c-horario',
  templateUrl: './c-horario.component.html',
  styleUrls: ['./c-horario.component.scss'],
})
export class CHorarioComponent extends CForm implements OnInit {
  @Output() update = new EventEmitter<any>();
  tipoHorario = [];
  @Input('horarios') lista = [];

  ngOnInit(): void {
    this.init();
    this._carregarForm();
  }

  private _carregarForm(): void {
    this.add('tipo').add('abre').add('fecha');
  }

  async init() {
    await this._carresgarTipoHorario();
  }
  private _carresgarTipoHorario(): void {
    this.httpService.tipoHorario().subscribe((data: any) => {
      this.tipoHorario = data;
    });
  }
  deleteItem(i: any) {
    this.lista = this.lista.filter((o: any) => o !== i);
    this.update.emit(this.lista);
  }
  adicionar() {
    if(this.lista === undefined) {
      this.lista = [];
    }
    this.lista.push(this.formulario.value);
    this.formulario.reset();
    this.update.emit(this.lista);
  }
}
