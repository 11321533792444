import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { QRCodeModule } from 'angularx-qrcode';
import { HttpService } from 'src/app/services/http.service';
import { CCardModule } from 'src/app/shared/c-card/c-card.module';
import { CHorarioModule } from 'src/app/shared/c-horario/c-horario.module';
import { CImagemModule } from 'src/app/shared/c-imagem/c-imagem.module';
import { CInputModule } from 'src/app/shared/c-input/c-input.module';
import { CSelectModule } from 'src/app/shared/c-select/c-select.module';
import { CTelefoneModule } from 'src/app/shared/c-telefone/c-telefone.module';
import { EmpresaComponent } from './empresa.component';

const rota: Routes = [
  {
    path: '',
    component: EmpresaComponent,
  },
];

@NgModule({
  declarations: [EmpresaComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CCardModule,
    QRCodeModule,
    CInputModule,
    CSelectModule,
    CHorarioModule,
    CTelefoneModule,
    CImagemModule,
    RouterModule.forChild(rota),
  ],
  providers : [HttpService],
  exports: [EmpresaComponent],
})
export class EmpresaModule {}
