import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { CForm } from '../../c-form';

@Component({
  selector: 'cardapio-adicionar',
  templateUrl: './adicionar.component.html',
  styleUrls: ['./adicionar.component.scss'],
})
export class AdicionarComponent extends CForm {
  lista = [];
  listaImg = [];
  formularioProduto: FormGroup;
  _img: any;
  itemSelecionado: any;
  ordem = 1;
  categoriaSelecionada: any;
  itemExcluir: any;
  itemEditar: any;
  grupoExcluir: any;
  nome: string;

  ngOnInit(): void {
    super.ngOnInit();
    this.loadScreen();
    this._carregarForm();
    this.carregarDadosFormFirebase();
    this.hideScreen(500);
  }

  async carregarDadosFormFirebase() {
    const cardapios = await this.db.recuperarCardapios(this.getEmail());

    cardapios.docs?.forEach(async (c) => {
      if (c.data().id === this.parametros.id) {
        this.nome = c.data().nome;

        this.lista = Array.isArray(c.data().dados)
          ? c.data().dados
          : c.data().dados.dados;

        this.lista.forEach((i) => {
          i.produtos.forEach(async (e) => {
            if (e.ext) {
              var _img = await this.db
                .downloadFile(
                  `cardapio/${this.empresa.id}/produtos/${e.id}.${e.ext}`
                )
                .toPromise();

              e._img = _img;
              this.listaImg.push({
                id: e.id,
                ext: e.ext,
                imagem: _img,
              });
            }
          });
        });
      }
    });
  }

  private _carregarForm(): void {
    this.add('nome');
    this.formularioProduto = this.formBuild.group({});

    this.formularioProduto.addControl(
      'nome',
      new FormControl('', Validators.required)
    );
    this.formularioProduto.addControl('valor', new FormControl(''));
    this.formularioProduto.addControl('descricao', new FormControl(''));

    this.formularioProduto.addControl('categoria', new FormControl(''));
  }

  selecionarItem(item: any, categoria: any) {
    this.categoriaSelecionada = categoria;
    this.itemSelecionado = item;
    this.formularioProduto.get('nome').setValue(item.nome);
    this._img = this.getImagem(item.imagem);
    this.formularioProduto
      .get('valor')
      .setValue(this.formatarValor(item.valor));
    this.formularioProduto.get('descricao').setValue(item.descricao);
  }

  adicionarProduto() {
    let obj = this.formularioProduto.value;

    if (obj?.nome === null) {
      this.toast.onShow.emit({
        mensagem: `Informe o nome`,
        tempo: 50 * 100,
        titulo: 'Erro',
        tipo: 'alert-warning',
      });
      return;
    }

    if (!this.categoriaSelecionada.produtos) {
      this.categoriaSelecionada.produtos = [];
    }

    obj.valor = Number.parseFloat(obj.valor).toFixed(2);

    if (obj.valor === 'NaN') {
      obj.valor = null;
    }
    var _ext = '';
    if (this._img !== undefined) {
      _ext = this._img.split(',')[0].split('/')[1].split(';')[0];
    }

    if (this.itemSelecionado !== undefined) {
      const idx = this.categoriaSelecionada.produtos.indexOf(
        this.itemSelecionado
      );
      obj.id = this.itemSelecionado.id;
      obj.ordem = this.itemSelecionado.ordem;
      obj.ext = _ext;
      this.categoriaSelecionada.produtos[idx] = obj;
    } else {
      obj.id = this.getId();
      obj.ext = _ext;
      obj.ordem = this.ordem++;
      this.categoriaSelecionada.produtos.push(obj);
    }

    this.listaImg.push({
      id: obj.id,
      ext: _ext,
      imagem: this._img,
    });
    obj.imagem = obj.id;

    this.uploadImagem(
      `cardapio/${this.empresa.id}/produtos/`,
      obj.id,
      this._img
    );

    this.categoriaSelecionada.produtos =
      this.categoriaSelecionada.produtos.sort((a, b) => a.ordem < b.ordem);
    this.reset();
    this.categoriaSelecionada = undefined;
  }

  getImagem(id: String) {
    const a = this.listaImg.filter((e) => e.id === id);

    if (a.length === 0) {
      return;
    }
    return a[0].imagem;
  }

  atualizarImagem(imagem) {
    var b = undefined;
    if (this.itemSelecionado !== undefined && this.itemSelecionado !== null) {
      b = this.listaImg.filter((i) => i.id === this.itemSelecionado.id);
    }

    this._img = imagem;
    if (b !== undefined) {
      const x = this.listaImg.indexOf(b[0]);
      if (x === -1) {
        return;
      }
      this.listaImg[x].imagem = imagem;
    }
  }
  onBlur(event) {
    if (event.target.value !== '') var tmp = event.target.value;
    event.target.value = this.formatarValor(tmp);
  }

  reset(item?: any) {
    this.categoriaSelecionada = item;
    this._img = undefined;
    this.itemSelecionado = undefined;
    this._img = undefined;
    this.formularioProduto.reset();
  }
  formatarValor(valor: string) {
    if (valor === null || valor === undefined) {
      return null;
    }
    valor = valor.replace('.', '');
    if (valor.indexOf(',') === -1) {
    }

    return (parseInt(valor.replace(/[^0-9]/g, '')) / 100).toLocaleString(
      'pt-BR',
      { minimumFractionDigits: 2 }
    );
  }

  async salvar() {
    this.loadScreen('Salvando');

    if (this.nome === undefined) {
      this.nome = this.parametros.nome;
    }

    const a = await this.db.adicionarCardapio(
      this.parametros.id,
      this.nome,
      this.usuarioLogado.email.toString(),
      this.lista
    );
    this.hideScreen(500);
    this.toast.onShow.emit({
      mensagem: 'Registro foi salvo',
      tempo: 50 * 100,
      titulo: 'Sucesso',
      tipo: 'alert-success',
    });
  }
  selectItemDelete(grupo: any, item?: any) {
    this.itemExcluir = item;
    this.grupoExcluir = grupo;
  }

  selectItemEdit(item?: any) {
    this.itemEditar = item;
    this.formularioProduto.get('categoria').setValue(this.itemEditar.categoria);
  }

  deletarCategoria() {
    if (this.itemExcluir === undefined) {
      this.lista = this.lista.filter((o: any) => o.id !== this.grupoExcluir.id);
    } else if (this.itemExcluir?.valor) {
      this.lista.forEach((e) => {
        if (e.id == this.grupoExcluir.id) {
          e.produtos = e.produtos.filter(
            (p: any) => p.id !== this.itemExcluir.id
          );
        }
      });
    }
  }
  adicionarCategoria() {
    if (
      this.formulario.get('nome').value === null ||
      this.formulario.get('nome').value === undefined
    ) {
      return;
    }
    this.lista.push({
      categoria: this.formulario.get('nome').value,
      id: this.getId(),
    });
    this.formulario.reset();
  }

  editarCategoria() {
    if (
      this.formularioProduto.get('categoria').value === null ||
      this.formularioProduto.get('categoria').value === undefined
    ) {
      return;
    }

    var i = this.lista.findIndex((p: any) => p.id == this.itemEditar.id);

    this.lista[i].categoria = this.formularioProduto.get('categoria').value;

    //this.formularioProduto.reset();
  }
}
