import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CComponent } from '../c-component';
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'c-input',
  templateUrl: './c-input.component.html',
  styleUrls: ['./c-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CInputComponent),
      multi: true,
    },
  ],
})
export class CInputComponent extends CComponent {
 @Output() onClick = new EventEmitter();
 @Input('mask') mascara: string;
 @Input() separatorLimit: string;
 @Input() thousandSeparator: string;
 @Input() decimalMarker: string;
 @Output('onBlur') blur = new EventEmitter();

 blurEmitter(ev){
  this.blur.emit(ev);
 }
}
