<form [formGroup]="formulario">
  <table class="table table-striped">
  <tr>
    <th>Dia da Semana</th>
    <th>Número</th>
    <th style="width: 40px">Ação</th>
  </tr>
  <tr *ngFor="let i of lista; let idx = index" [class]="idx % 2? '' : 'striped'">
    <td>{{i.tipo}}</td>
    <td>{{i.numero | mask: '(00) 0 0000-0000' }} </td>
    <td style="padding: 2px;">
      <button type="button" class="btn btn-danger" (click)="deleteItem(i)">
        <i class="fa  fa-trash-o"></i>
      </button>
    </td>
  </tr>
</table>
<div class="row">
  <div class="col-lg-5 col-xs-12">
    <c-select rotulo="Selecione" formControlName="tipo" [options]="tipoTelefone"></c-select>
  </div>
  <div class="col-lg-3 col-xs-12">
    <c-input rotulo="Número" formControlName="numero" mask="(00) 0 0000-0000"></c-input>
  </div>

  <div class="col-lg-1 col-xs-12">
    <div class="form-group" style="margin-top: 2.2rem;">
      <button type="button" class="btn btn-primary" (click)="adicionar()">
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </div>
</div>
</form>
