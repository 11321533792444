import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CForm } from '../c-form';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-cardapio',
  templateUrl: './cardapio.component.html',
  styleUrls: ['./cardapio.component.scss'],
})
export class CardapioComponent extends CForm {
  cardapios: any[] = [];
  novoCardapio: string;

  ngOnInit(): void {
    this.loadScreen();
    super.ngOnInit();
    this.init();
    this.hideScreen(500);
  }

  async init() {
    const r = await this.db.recuperarCardapios(this.getEmail());
    this.cardapios = [];
    //console.log('CARDAPIOS RECEBEU', r);
    r.docs.forEach((d: any) => {
      this.cardapios.push(d.data());
    });
    //console.log('CARDAPIOS', this.cardapios);
  }

  adicionar() {
    // this.router.navigate(['cardapio/adicionar']);
    const novo = {
      id: uuidv4(),
      nome: this.novoCardapio,
    };
    //console.log(novo);
    this.router.navigate(['cardapio/adicionar', novo]);
  }

  transferir(item: any) {
    this.router.navigate([`transferencia/${item.id}`]);
  }
  async editar(item: any) {
    this.router.navigate([`cardapio/editar/${item.id}`]);
  }

  async ativar(id: string) {
    this.loadScreen('Atualizando');
    //console.log('ativando...', id);
    await this.db.ativarCardapio(this.getEmail(), id);
    this.hideScreen(500);
    location.reload();
  }
}
