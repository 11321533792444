import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { CInputModule } from '../c-input/c-input.module';
import { CSelectModule } from '../c-select/c-select.module';
import { CHorarioComponent } from './c-horario.component';



@NgModule({
  declarations: [CHorarioComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule,
    CSelectModule,
    CInputModule
  ],
  exports : [CHorarioComponent]
})
export class CHorarioModule { }
