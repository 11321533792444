<div
  class="logo-img"
  (click)="clickImagem()"
  [ngStyle]="{ 'width.px': width, 'height.px': height }"
>

<div
*ngIf="url">
<img [src]="this.url" alt="" [ngStyle]="{ 'width.px': width, 'height.px': height }">
</div>

  <div *ngIf="!url" style="padding-top: 10px">
    <i
      class="glyphicon glyphicon-picture"
      style="font-size: {{ width / 2 }}px;"
    ></i>
    <p *ngIf="!esconderBotao">CLIQUE PARA TROCAR A IMAGEM</p>
  </div>
</div>

<br />

<div class="row">
  <div class="col-lg-12 col-xs-12">
    <div class="centro" style="width: {{ width }}px" *ngIf="!esconderBotao">
      <button
        type="button"
        class="btn btn-block btn-success"
        (click)="imageInput.click()"
      >
        Trocar Imagem
      </button>
    </div>
  </div>
  <br />
  <div class="col-lg-12 col-xs-12" *ngIf="mostarInfo">
    <div
      style="width:{{
        width
      }}px; display: block; margin-left:auto; margin-right:auto;"
    >
      <div class="alert alert-warning alert-dismissible">
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-hidden="true"
        >
          &times;
        </button>
        <h4><i class="icon fa fa-warning"></i> Atenção!</h4>
        Para melhor visualização, a imagem deve ter ao mínimo {{ width }}px
        (largura) por {{ height }}px (altura).
      </div>
    </div>
  </div>
</div>

<input
  type="file"
  style="visibility: hidden; position: absolute"
  #imageInput
  (change)="onSelectFile($event)"
/>
<br />
