import { Component, OnInit } from '@angular/core';
import { UsuarioLogado } from 'src/app/services/auth.service';
import { CForm } from '../c-form';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends CForm {
  isAuth = false;
  usuario: UsuarioLogado;

  async login() {
    this.loading.onShow.emit('Aguarde');
    this.isAuth = await this.auth.doGoogleLogin();
    this.usuario = this.auth.getUser();

    if (this.usuario !== null && this.usuario !== undefined) {
      setTimeout(() => {
        this.loading.onHide.emit();
        window.open('/home', '_self');
      }, 1000);
    } else {
      this.loading.onHide.emit();
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    //console.log('this.data ', this.data);
    if (this.data.sair === true) {
      this.auth.sair().then(() => {
        this.router.navigate(['/login']);
      });
    }
  }
}
