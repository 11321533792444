<h3 class="cardapioTitulo">{{ this.nome | uppercase}}</h3>
<form [formGroup]="formulario">
  <div class="box box-widget">
    <div class="row">
      <div class="col-lg-10 col-xs-12">
        <div class="modal-footer">
          <div class="col-lg-12 col-xs-12" style="text-align: left">
            <c-input (onClick)="adicionarCategoria()" rotulo="Categoria:" placeholder="Adicionar Categoria"
              formControlName="nome" icone="fa fa-fw fa-plus"></c-input>
          </div>
        </div>
      </div>

      <div class="col-lg-2 col-xs-12" style="padding-top: 40px; padding-right: 30px">
        <button type="button" class="btn btn-primary" style="width: 100%" (click)="salvar()">
          Salvar
        </button>
      </div>
    </div>
  </div>
</form>

<form [formGroup]="formularioProduto">
  <c-card *ngFor="let i of lista" [titulo]="i.categoria">
    <div tools>
      <button type="button" class="btn btn-info" data-toggle="modal" data-target="#modal-editar"
        (click)="selectItemEdit(i)">
        <i class="fa fa-edit"></i>
      </button>
      &nbsp;
      <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#modal-default"
        (click)="selectItemDelete(i)">
        <i class="fa fa-trash"></i>
      </button>
      &nbsp;
      <button type="button" (click)="reset(i)" class="btn btn-success" data-toggle="modal"
        data-target="#modal-adicionar">
        <i class="fa fa-plus"></i>
      </button>
    </div>
    <div>
      <ul class="products-list product-list-in-box">
        <li class="item" *ngFor="let item of i.produtos">
          <div class="product-img" style="padding-right: 10px">
            <div style="height: 140px; padding: 4px">
              <div style="padding: 4px">
                <button type="button" class="btn btn-block btn-danger" data-toggle="modal" data-target="#modal-default"
                  (click)="selectItemDelete(i,item)">
                  Remover
                </button>
              </div>

              <c-imagem [abrirClick]="false" [width]="80" [height]="80"
                [imagem]="item._img ? item._img : getImagem(item.imagem)" [esconderBotao]="true" [mostarInfo]="false">
              </c-imagem>
            </div>
          </div>
          <div class="product-info">
            <a href="#" class="product-title" style="font-size: 2rem" (click)="selecionarItem(item, i)"
              data-toggle="modal" data-target="#modal-adicionar">
              {{ item.nome }}

              <span *ngIf="item.valor" class="label label-default pull-right" style="font-size: 2rem">{{ item?.valor |
                currency: "R$ " }}</span></a>
            <span class="product-description">
              {{ item.descricao }}
            </span>
          </div>
        </li>
      </ul>
    </div>

    <div class="modal fade" id="modal-default">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Excluir</h4>
          </div>
          <div class="modal-body">
            <p>Deseja excluir?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Não
            </button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deletarCategoria()">
              Sim
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="modal-editar">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Editar</h4>
          </div>
          <div class="modal-body">
            <c-input [obrigatorio]="true" rotulo="" formControlName="categoria" placeholder="">
            </c-input>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Cancelar
            </button>
            <button type="button" class="btn btn-success" data-dismiss="modal" (click)="editarCategoria()">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>

  </c-card>

  <div class="modal fade" id="modal-adicionar">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Adicionar Produto</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-4 col-xs-12">
              <c-imagem [width]="150" [imagem]="_img" [height]="150" (updateImage)="atualizarImagem($event)"
                [mostarInfo]="false"></c-imagem>
            </div>

            <div class="col-lg-8 col-xs-12">
              <div class="row">
                <div class="col-lg-12 col-xs-12">
                  <c-input [obrigatorio]="true" rotulo="Nome:*" formControlName="nome" placeholder="Nome do produto">
                  </c-input>
                </div>

                <div class="col-lg-12 col-xs-12">
                  <c-input (onBlur)="onBlur($event)" rotulo="Valor:" formControlName="valor" separatorLimit="1000"
                    mask="separator.2" placeholder="Informe o valor" decimalMarker="," thousandSeparator="."></c-input>
                </div>

                <div class="col-lg-12 col-xs-12">
                  <c-input [textarea]="true" formControlName="descricao" placeholder="Informe uma descrição"
                    rotulo="Descrição:"></c-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">
            Cancelar
          </button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="adicionarProduto()">
            {{ itemSelecionado === undefined ? "Adicionar" : "Atualizar" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>