<div class="form-group">
  <label>{{rotulo}}</label>
  <select class="form-control"
  (onChange)="onChangeSelect.emit($event)"
  [(ngModel)]="value"
  [id]="id"
  >
    <option
     *ngFor="let i of options" value="{{i.value}}">{{i.label}}</option>
  </select>
</div>
