
<c-card titulo="Transferência" *ngIf="isTransferido === false">

  <div class="box-body">
    <table class="table table-bordered">
      <tr>
        <th style="width: 90%">Nome : {{item?.nome}}</th>
        <th style="width: 10%">Itens :{{item?.dados?.length || 0}}</th>
      </tr>

      <tr *ngFor="let c of item?.dados">
        <td >
          Categoria : {{c?.categoria}}
        </td>
        <td >
          Produtos : {{c?.produtos?.length || 0}}
        </td>
      </tr>
      </table>
      </div>

      <div footer>
        <form [formGroup]="formulario">
        <c-input
        icone="fa fa-send-o"
        (onClick)="enviarEmail()"
        [obrigatorio]='true' rotulo="Informe o e-mail:" type="e-mail" formControlName="email"></c-input>
      </form>
      </div>

</c-card>
