import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MensagemComponent } from './mensagem.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CCardModule } from 'src/app/shared/c-card/c-card.module';
import { CInputModule } from 'src/app/shared/c-input/c-input.module';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxPrintModule } from 'ngx-print';


const rota: Routes = [
  {
    path: '',
    component: MensagemComponent,
  }
];



@NgModule({
  declarations: [MensagemComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CCardModule,
    CInputModule,
    QRCodeModule,
    NgxPrintModule,
    RouterModule.forChild(rota),
  ],
  exports : [MensagemComponent]
})
export class MensagemModule { }
