import { Component, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { EventEmitter } from 'events';

@Component({
  template: '<div></div>',
 })

export class CComponent  implements ControlValueAccessor{

  @Input() isPendente = false;
  @Input() rotulo: string;
  @Input() id: string;
  @Input() type: 'text';
  @Input() obrigatorio = false;
  @Input() dica: string;
  @Input() isReadOnly = false;
  @Input() disabled = false;
  @Input() placeholder: string;
  @Input() textarea = false;
  @Input() rows = 3;
  @Input() icone:string;



  innerValue: any = '';
  onChange: any = () => {};
  onTouch: any = () => {};

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue){
      this.innerValue = v;
      this.onChange(v);
    }
  }

  writeValue(v: any): void {
    if (v !== this.value){
      this.value = v;
      this.onChange(v);
    }
  }


  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isPendente = isDisabled;
  }

}
