<c-card [titulo]="'Cardápios'">
  <div tools>
    <button type="button" class="btn btn-success" data-toggle="modal" data-target="#modal-default"><i
        class="fa fa-plus"></i>&nbsp; Novo Cardápio</button>


    <div class="modal fade" id="modal-default">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Cardápio</h4>
          </div>
          <div class="modal-body">

            <c-input rotulo="Nome do Cardápio" placeholder="Ex.: Almoço" [(ngModel)]="novoCardapio"></c-input>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Cancelar
            </button>
            <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="adicionar()">
              Salvar

            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

  </div>

  <div class="box-body">
    <table class="table table-bordered">
      <tr>
        <th style="width: 5%"></th>
        <th style="width: 75%">Nome</th>
        <th style="width: 10%">Categoria(s)</th>
        <th style="width: 10%">Ativo</th>
      </tr>
      <tr *ngFor="let c of cardapios">
        <td style="padding: 1px;display:flex; align-content: space-between;">

          <div style="padding: 1px;">
            <button type="button" class="btn btn-sm btn-info" (click)="editar(c)">
              <i class="fa  fa-pencil"></i>
            </button>
          </div>
          <div style="padding: 1px;">
            <button type="button" class="btn btn-sm btn-success" (click)="transferir(c)">
              <i class="fa  fa-mail-forward"></i>
            </button>
          </div>


        </td>

        <td>
          &nbsp;<b> {{c.nome}}</b>
        </td>

        <td>
          {{c.dados.length}}
        </td>

        <td style="padding: 2px;">

          <span class="label label-success" *ngIf="empresa?.ativo === c.id ">Sim</span>
          <button (click)="ativar(c.id)" *ngIf="empresa?.ativo === undefined || empresa?.ativo !== c.id " type="button"
            class="btn btn-sm btn-primary">Ativar</button>

        </td>

      </tr>

    </table>
  </div>
</c-card>