import { Component, OnInit } from '@angular/core';
import { CForm } from '../c-form';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss'],
})
export class EmpresaComponent extends CForm {
  ufs = [];
  horarios = [];
  telefones = [];
  logo: string;
  empresa: any;
  ngOnInit(): void {
    super.ngOnInit();
    this.loadScreen();
    this.init();
  }

  async init() {
    this._carregarForm();
    await this._carregarUF();
    this.empresa = await this.db.recuperarDadosEmpresa(
      this.usuarioLogado.email.toString()
    );
    this.atualizarEmpresa();
    this.hideScreen(500);
  }

  async atualizarEmpresa() {
    this.formulario.get('nome').setValue(this.empresa?.dados?.nome);
    this.formulario.get('descricao').setValue(this.empresa?.dados?.descricao);

    this.formulario.get('descricao').setValue(this.empresa?.dados?.descricao);
    this.formulario.get('logradouro').setValue(this.empresa?.dados?.logradouro);
    this.formulario.get('bairro').setValue(this.empresa?.dados?.bairro);
    this.formulario.get('municipio').setValue(this.empresa?.dados?.municipio);
    this.formulario
      .get('complemento')
      .setValue(this.empresa?.dados?.complemento);
    this.formulario.get('uf').setValue(this.empresa?.dados?.uf);
    this.formulario.get('numero').setValue(this.empresa?.dados?.numero);
    this.formulario.get('cep').setValue(this.empresa?.dados?.cep);

    this.telefones = this.empresa?.dados?.telefones;
    this.horarios = this.empresa?.dados?.horarios;

    if (this.empresa === null) {
      return;
    }
    this.db.downloadFile(`cardapio/${this.empresa.id}/logo`).subscribe(
      (img) => {
        this.logo = img;
      },
      (err) => {
        //console.log('ERRO AO CARREGAR IMAGEM');
      }
    );
  }

  private _carregarForm(): void {
    this.add('nome')
      .add('descricao')
      .add('logradouro')
      .add('bairro')
      .add('municipio')
      .add('complemento')
      .add('uf')
      .add('numero')
      .add('cep');
  }
  buscaCep() {
    this.loadScreen('Buscando CEP');
    this.httpService.buscaCep(this.formulario.get('cep').value).subscribe(
      (data: any) => {
        if (data.erro) {
          this.hideScreen();
          return;
        }
        const { logradouro, complemento, bairro, ibge, localidade, uf } = data;
        this.formulario.get('logradouro').setValue(logradouro);
        this.formulario.get('bairro').setValue(bairro);
        this.formulario.get('municipio').setValue(localidade);
        this.formulario.get('complemento').setValue(complemento);
        this.formulario.get('uf').setValue(uf);
        this.hideScreen(500);
      },
      (error: any) => {
        this.hideScreen();
      }
    );
  }
  atualizarHorario(dados: any) {
    this.horarios = dados;
  }
  atualizarTelefone(dados: any) {
    this.telefones = dados;
  }

  atualizarImagem(imagem: any) {
    this.logo = imagem;
  }

  private _carregarUF(): void {
    this.httpService.uf().subscribe((data: any) => {
      this.ufs = data;
    });
  }

  async salvar() {
    window.scrollTo(0, 0);
    this.loadScreen();
    let obj: any = this.formulario.value;
    obj.horarios = this.horarios;
    obj.telefones = this.telefones;
    obj = JSON.parse(JSON.stringify(obj));
    await this.db.atualizarEmpresa(this.usuarioLogado.email.toString(), obj);
    this.uploadImagem();

    this.hideScreen(500);
  }
  uploadImagem() {
    var blob = this.utils.base64ToFile(this.logo);
    var file = new File([blob], 'fileName.jpeg', {
      type: "'image/jpeg'",
    });

    this.db.uploadFile(`cardapio/${this.empresa.id}/logo`, file);
  }
}
