import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { QRCodeModule } from 'angularx-qrcode';
import { CCardModule } from 'src/app/shared/c-card/c-card.module';
import { HomeComponent } from './home.component';

const rota: Routes = [
  {
    path: '' , component: HomeComponent
  }
];


@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    FormsModule,
    CCardModule,
    QRCodeModule,
    RouterModule.forChild(rota),
  ],
  exports : [HomeComponent]
})
export class HomeModule { }
