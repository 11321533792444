import { Component, OnInit } from '@angular/core';
import { CForm } from '../../c-form';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
})
export class PrintComponent extends CForm {
  //https://meufregues.com.br/v/9f2f4d7c-4fc7-40f4-9607-8905182faa5d/91dlpliao

  url: string;
  logo: string;
  numero: string;
  ngOnInit(): void {
    super.ngOnInit();

    this.loadScreen('Carregando');

    setTimeout(async () => {
      const mesa = await this.db.recuperarMesaPorId(
        this.getEmail(),
        this.parametros.id
      );
      this.numero = this.getNum(mesa.numero);
      this.logo = await this.download();
      //console.log('LOGO', this.logo);
      this.hideScreen();
      this.url = `https://meufregues.com.br/v/${this.empresa?.id}/${this.parametros.id}`;
      await setTimeout(() => {
        window.print();
        window.close();
      }, 1000);
    }, 1000);
  }
  getNum(num: string) {
    if (parseInt(num) < 9) {
      return `0${num}`;
    }

    return num;
  }

  download() {
    return this.db
      .downloadFile(`cardapio/${this.empresa?.id}/logo`)
      .toPromise();
  }
}
