import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdicionarComponent } from './adicionar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { CInputModule } from 'src/app/shared/c-input/c-input.module';
import { CCardModule } from 'src/app/shared/c-card/c-card.module';
import { CImagemModule } from 'src/app/shared/c-imagem/c-imagem.module';
import { ToRealPipe } from 'src/app/to-real.pipe';

@NgModule({
  declarations: [AdicionarComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule,
    CInputModule,
    CCardModule,
    CImagemModule,
  ],
  providers: [ToRealPipe],
  exports: [AdicionarComponent],
})
export class AdicionarModule {}
