<div class="row" *ngIf="empresa.aberto === false">
  <div class="fechado">
    Fechado
  </div>
  <div class="fechado-desc">
    Volte em breve
  </div>

</div>

<div class="row" *ngIf="empresa.aberto === true">
  <div class="col-lg-3 col-xs-12 v_logo">
    <img [src]="logo" width="200" />
  </div>

  <div class="col-lg-9 col-xs-12 v_dados">
    <h2 class="v_titulo">{{ empresa?.dados?.nome }}</h2>
    <p class="v_descricao">
      {{ empresa?.dados?.descricao }}
    </p>

    <p class="v_endereco">
      <i class="icon fa fa-map-marker"></i>
      {{ empresa?.dados?.logradouro }}
      {{ empresa?.dados?.bairro }}
      {{ empresa?.dados?.municpio }}
      - {{ empresa?.dados?.uf }}
    </p>
  </div>

  <div class="col-lg-3 col-xs-12"></div>
  <div class="col-lg-9 col-xs-12">
    <div *ngFor="let h of empresa?.dados?.horarios" class="v_horario">
      <p>
        <i class="icon fa fa-clock-o"></i> {{ h.tipo }} -
        {{ h.abre | mask: "Hh:m0" }} / {{ h.fecha | mask: "Hh:m0" }}
      </p>
    </div>
  </div>

  <div class="col-lg-12 col-xs-12" style="padding-top: 20px" *ngFor="let c of cardapio?.dados">
    <c-card [titulo]="c.categoria">
      <div [class]="i % 2 == 0 ? 'row z' : 'row '" *ngFor="let p of c.produtos; let i = index" style="padding: 4px">
        <div class="col-lg-1 col-xs-12">
          <img [src]="getImagem(p.imagem)" width="60" *ngIf="p.imagem" />

          <div *ngIf="!p.imagem" style="height: 60px; text-align: center">
            <i class="glyphicon glyphicon-picture" style="font-size: 30px"></i>
          </div>
        </div>
        <div class="col-lg-4 col-xs-12">
          {{ p.nome }}
        </div>
        <div class="col-lg-6 col-xs-12">
          {{ p.descricao }}
        </div>
        <div class="col-lg-1 col-xs-12">
          <span class="label label-default pull-right" style="font-size: 1.5rem">{{ p.valor | currency: "R$ " }}</span>
        </div>
      </div>
    </c-card>
  </div>
</div>