import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CSelectComponent } from './c-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [CSelectComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports : [CSelectComponent]
})
export class CSelectModule { }
