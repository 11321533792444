import { LoadingService } from './../../services/loading.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'c-loading',
  templateUrl: './c-loading.component.html',
  styleUrls: ['./c-loading.component.scss']
})
export class CLoadingComponent{

  displayModal = false;
  mensagem:string;

  constructor(
    public loading:LoadingService
  ) {

    this.loading.onShow.subscribe((data: any) => {
      if( data !== null && data !== undefined ){
        this.mensagem = data;
      }
      this.displayModal = true;
    });

    this.loading.onHide.subscribe(() => {
      setTimeout( () => {
        this.displayModal = false;
      }, 200);
    });

  }



}
