import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { CInputModule } from '../c-input/c-input.module';
import { CSelectModule } from '../c-select/c-select.module';
import { CTelefoneComponent } from './c-telefone.component';


@NgModule({
  declarations: [CTelefoneComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule,
    CSelectModule,
    CInputModule
  ],exports : [CTelefoneComponent]
})
export class CTelefoneModule { }
