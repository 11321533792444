import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'c-card',
  templateUrl: './c-card.component.html',
  styleUrls: ['./c-card.component.scss'],
})
export class CCardComponent implements OnInit {
  @Input() titulo: string;
  @Input() styleClass:string;
  @Input() carregando = false;
   constructor() {}

  ngOnInit(): void {}
}
