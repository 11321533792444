import { FirebaseService } from './../services/firebase.service';
import { LoadingService } from './../services/loading.service';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { AuthService, UsuarioLogado } from '../services/auth.service';
import { HttpService } from '../services/http.service';
import { UtilsService } from '../services/utils.service';
import { ToastService } from '../services/toast.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'g-form',
  template: '<div></div>',
})
export class CForm implements OnInit, OnDestroy {
  parametros:any;
  data:any;
  erros: number;
  @Output() onUpdateForm = new EventEmitter<any>();
  @Output() onUpdateFormStatus = new EventEmitter<any>();
  public usuarioLogado: UsuarioLogado;
  public empresa:any;
  formulario: FormGroup;

  constructor(
    public auth: AuthService,
    public formBuild: FormBuilder,
    public http: HttpClient,
    public httpService:HttpService,
    public loading:LoadingService,
    public toast: ToastService,
    public db: FirebaseService,
    public utils:UtilsService,
    public router: Router,
    public activatedRoute: ActivatedRoute,

  ) {

    this.formulario = this.formBuild.group({});

    this.activatedRoute.params.subscribe((d: any) => {
      this.parametros = d;
    });

    this.activatedRoute.data.subscribe( (d:any) => {
      this.data = d;
    });

  }

  getEmail(): string{
    return this.usuarioLogado?.email?.toString();
  }

  ngOnInit(): void {
    this.usuarioLogado = this.auth.getUser();

    this._init();
  }

  async _init(){
    await this.carregarEmpresa();
    this._formStatusChanges();
    this._formValueChanges();
    this._updateEmitter();

    if(this.empresa === null || this.empresa === undefined) {
      await this.carregarEmpresa();
      if(this.empresa === null || this.empresa === undefined) {
        //await this.db.iniciarEmpresa(this.getEmail());
        //  await this.carregarEmpresa();
      }
    }

  }

  async carregarEmpresa(){
    const email = this.getEmail();
    if(email === undefined ){
      return;
    }

    this.empresa = await this.db.recuperarDadosEmpresa(email);

  }

  uploadImagem(caminho:string,nomeArquivo:string, b64:string){
    if(b64 === undefined || b64 === null){
      return;
    }
    const ext = b64.split(';')[0].split(':')[1].split('/')[1];
    const blob = this.utils.base64ToFile(b64);
    const file = new File([blob], `${nomeArquivo}.${ext}`, {
      type: b64.split(';')[0].split(':')[1],
    });

    this.db.uploadFile(`${caminho}/${nomeArquivo}.${ext}`, file);
  }

  ngOnDestroy(): void {
    this.onUpdateForm.unsubscribe();
    this.onUpdateFormStatus.unsubscribe();
  }


  loadScreen(label?: string): void {
    this.loading.onShow.emit(label);
  }
  hideScreen(ms?: number): void {
    setTimeout(() => {
      this.loading.onHide.emit();
    }, ms);
  }

  add(
    nome: string,
    estado?: string,
    validadores?: ValidatorFn | ValidatorFn[]
  ): CForm {
    this.addControl(nome, estado, validadores);
    return this;
  }
  addControl(
    nome: string,
    estado: string,
    validadores?: ValidatorFn | ValidatorFn[]
  ): void {
    if (nome === null || nome === undefined || nome.trim() === '') {
      throw new Error(' [ ERRO :: INFORME O NOME DO CAMPO ]');
    }
    this.formulario.addControl(nome, new FormControl(estado, validadores));
    this._updateEmitter();
  }
  addGroup(a: any): FormGroup {
    return this.formBuild.group(a);
  }
  _updateEmitter(): void {
    if (this.onUpdateForm) {
      this.onUpdateForm.emit(this.formulario);
    }
  }



  private _formValueChanges(): void {
    this.formulario.valueChanges.subscribe(() => {
      this.erros = 0;
      Object.keys(this.formulario.controls).forEach((field) => {
        const control = this.formulario.get(field);
        if (control !== null && control.errors !== null) {
          this.erros++;
        }
      });
      this._updateEmitter();
    });
  }

  private _formStatusChanges(): void {
    this.formulario.statusChanges.subscribe((data: string) => {
      const statusForm = data === 'VALID' ? true : false;

      if (
        this.onUpdateFormStatus !== null ||
        this.onUpdateFormStatus !== undefined
      ) {
        this.onUpdateFormStatus.emit(statusForm);
      }
    });
  }

  getId() {
    return Math.random().toString(36).substr(2, 9);
  }
}
