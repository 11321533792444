<div class="imprimir">

<div class="numero">
  {{numero}}
</div>

<div class="qrcode">
  <qrcode [qrdata]="url" width="200"></qrcode>
</div>

<div class="logo">
  <img [src]="logo" height="150px">
</div>



<img src="../../../../assets/menu/menu1.png">
</div>

