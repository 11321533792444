import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardapioModule } from './modules/cardapio/cardapio.module';
import { EmpresaModule } from './modules/empresa/empresa.module';
import { HomeModule } from './modules/home/home.module';
import { ImprimirModule } from './modules/imprimir/imprimir.module';
import { LoginModule } from './modules/login/login.module';
import { MensagemModule } from './modules/mensagem/mensagem.module';
import { TransferenciaModule } from './modules/transferencia/transferencia.module';
import { VModule } from './modules/v/v.module';
import { AuthService } from './services/auth.service';
import { FirebaseService } from './services/firebase.service';
import { UtilsService } from './services/utils.service';
import { CAlertaModule } from './shared/c-alerta/c-alerta.module';
import { CCardModule } from './shared/c-card/c-card.module';
import { CLoadingModule } from './shared/c-loading/c-loading.module';
import { CTopoModule } from './shared/c-topo/c-topo.module';
import { ToRealPipe } from './to-real.pipe';

registerLocaleData(localePt, 'pt');

export const maskConfig: Partial<IConfig> = {
  thousandSeparator: '.',
  decimalMarker: ',',
};

@NgModule({
  declarations: [AppComponent, ToRealPipe],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firease),
    NgxMaskModule.forRoot(maskConfig),
    CCardModule,
    HomeModule,
    EmpresaModule,
    CardapioModule,
    CLoadingModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    NgxMaskModule,
    MensagemModule,
    CTopoModule,
    CAlertaModule,
    LoginModule,
    VModule,
    TransferenciaModule,
    ImprimirModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt_BT',
    },
    ,
    AuthService,
    FirebaseService,
    UtilsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
