import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'c-logo',
  templateUrl: './c-logo.component.html',
  styleUrls: ['./c-logo.component.scss']
})
export class CLogoComponent implements OnInit {
  public env = environment;
  constructor() { }

  ngOnInit(): void {

  }

}
