import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { CInputComponent } from './c-input.component';



@NgModule({
  declarations: [CInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule
  ],exports :[ CInputComponent]
})
export class CInputModule { }
