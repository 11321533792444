import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VComponent } from './v.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CCardModule } from 'src/app/shared/c-card/c-card.module';
import { NgxMaskModule } from 'ngx-mask';


const rota: Routes = [
  {
    path: '',
    component: VComponent,
  },
];


@NgModule({
  declarations: [VComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgxMaskModule,
    ReactiveFormsModule,
    CCardModule,
    RouterModule.forChild(rota),
  ],
  exports :[VComponent]
})
export class VModule { }
