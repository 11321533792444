import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CComponent } from '../c-component';

@Component({
  selector: 'c-select',
  templateUrl: './c-select.component.html',
  styleUrls: ['./c-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CSelectComponent),
      multi: true,
    },
  ],
})
export class CSelectComponent extends CComponent {
  @Input() options: any[];
  @Output() onChangeSelect = new EventEmitter();
}
