import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { CForm } from '../c-form';

@Component({
  selector: 'app-transferencia',
  templateUrl: './transferencia.component.html',
  styleUrls: ['./transferencia.component.scss']
})
export class TransferenciaComponent extends CForm implements OnInit{
  item:any;
  email:string;
  isTransferido =false;

  async ngOnInit(){
    super.ngOnInit();
    this.add('email', '', [Validators.email, Validators.required]);
    const l:any = await this.db.recuperarCardapios(this.getEmail());

    l.docs.forEach((d:any) => {
      if(d.id === this.parametros.id){
        this.item = d.data();
        return;
      }
    });


  }
  async enviarEmail(){
    if(this.formulario.invalid === true){
      this.toast.onShow.emit({
        mensagem: 'Email inválido',
        tempo: 50 * 100,
        titulo: 'Erro',
        tipo: 'alert-error',
      });
    }else{
      this.toast.onShow.emit({
        mensagem: 'Pedido de transferência realizado.',
        tempo: 50000 * 100,
        titulo: 'Sucesso',
        tipo: 'alert-success',
      });
      const id = await this.db.transferirPara(this.getEmail(),this.formulario.value.email.toString(), this.item);
      this.isTransferido = true;
      this.item = null;
    }
  }
}
