import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgxPrintModule } from 'ngx-print';
import { CCardModule } from 'src/app/shared/c-card/c-card.module';
import { CInputModule } from 'src/app/shared/c-input/c-input.module';
import { LoginComponent } from './login.component';


const rota: Routes = [
  {
    path: '',
    component: LoginComponent,
    data : {sair : false}
  },
  {
    path: 'sair',
    component: LoginComponent,
    data : {sair : true}
  },
];

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CCardModule,
    CInputModule,
    NgxPrintModule,
    RouterModule.forChild(rota),
  ],
  exports : [LoginComponent]
})
export class LoginModule { }
